import type {
  AdvancedValues, InputValues, RowData, HifasData, MaterialData,
  CustomMaterialCost, CustomVerfahrenDataMap, VerfahrenName, ConsumptionMaterial
} from "./Types";

// gibt für jedes Verfahren die Kosten für die gegebenen InputValues zurück
export function calcRowData(
  { werkstoff, dicke, waermeeinfluss, aspect, aspectRatio }: InputValues,
  consts: AdvancedValues,
  { verfahren }: HifasData,
  materialData: MaterialData,
  customMatCost: CustomMaterialCost,
  customVerfahren: CustomVerfahrenDataMap,
): RowData {
  // strom ist schon proH, der Rest ist pro minute
  const minToH = (cm: ConsumptionMaterial) => cm === "strom" ? 1 : 60;

  // gibt den Wert für das Verfahren v zurück
  const rowDataForVerfahren = (v: VerfahrenName) => {
    const { investitionssumme, auslastung, verschleissteile } = customVerfahren[v];
    // 80% Verfügbarkeit, zusätzlich zu prozessbedingter Auslastung
    const runtimePerY = consts.anz_schichten * consts.dauer_schicht * 0.8;
    const abschreibungPerH = investitionssumme * consts.wiederbeschaffung / 100 / (consts.nutzungszeit * runtimePerY);
    const zinsenPerH = investitionssumme / 2 * consts.zinssatz / 100 / runtimePerY;
    const versicherungPerH = investitionssumme * consts.versicherungskostenindex / 100 / runtimePerY;
    const mietePerH = consts.platzbedarf * consts.mietkosten_proMM * 12 / runtimePerY;
    const wartungInstH = investitionssumme * consts.wartungsindex / 100 / runtimePerY;
    const kostenWerkzKran = consts.kosten_werkzeuge + consts.kosten_kran;

    const allgKostenPerH = abschreibungPerH + zinsenPerH + versicherungPerH
      + mietePerH + wartungInstH + kostenWerkzKran;

    const leerlaufVebrauch = verfahren[v].idleConsumption;
    const leerlaufKosten = Object.keys(leerlaufVebrauch)
      .map((cm: ConsumptionMaterial) => customMatCost[cm] * leerlaufVebrauch[cm].value * minToH(cm))
      .reduce((ac, v) => ac + v, 0); // aufsummieren

    const leerLaufkostenProH = leerlaufKosten + allgKostenPerH + consts.personalkosten;
    // ist für diese Dicke ein Wert hinterlegt?
    const speedRow = materialData[v]?.[dicke]
    if (!speedRow || (aspect && aspectRatio > speedRow.aspekt)
      || (!waermeeinfluss && verfahren[v].general.waermeeinfluss?.value === 1)) {
      return { pos: false, price: 0, priceHour: 0, data: null }
    }

    // Verbrauchsmaterialen beim Schneidn
    const cutConsumeMat = Object.keys(speedRow).filter(k =>
      !["schnittgeschw", "aspekt", "anstechzeit"].includes(k));
    const cutConsumeCost = cutConsumeMat
      .map((cm: ConsumptionMaterial) => customMatCost[cm] * speedRow[cm] * minToH(cm))
      .reduce((ac, v) => ac + v, 0); // aufsummieren

    const costCuttingPerH = cutConsumeCost + verschleissteile;

    const speed = speedRow.schnittgeschw / 1000 * 60; // in meter/h now
    const effDauerProM = 1 / speed / (auslastung / 100);
    const cost = leerLaufkostenProH * effDauerProM + costCuttingPerH / speed;
    return {
      pos: true,
      price: cost,
      priceHour: leerLaufkostenProH + costCuttingPerH,
      data: speedRow,
    };
  };

  const ret = Object.assign({}, ...Object.keys(verfahren).map(v => ({
    [v]: rowDataForVerfahren(v)
  })));
  //console.log(ret);
  return ret;
}