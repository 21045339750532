import { AdvancedKeys } from "./Types";

type ChangableKeys = Extract<AdvancedKeys,
| "nutzungszeit"
| "zinssatz"
| "anz_schichten"
| "versicherungskostenindex"
| "mietkosten_proMM"
| "wartungsindex"
| "personalkosten"
| "platzbedarf"
>
export const ranges:Record<ChangableKeys, {min:number, max:number, step: number}> 
= {
  nutzungszeit: { min:3, max: 20, step: 1},
  zinssatz: {min:0.5, max: 15, step: 0.25},
  anz_schichten: {min:1, max:3, step: 1},
  versicherungskostenindex: {min: 0.0, max:5.0, step: 0.05},
  mietkosten_proMM: {min:0.5, max: 30, step: 0.1},
  wartungsindex: {min: 0.0, max: 10, step:0.1},
  personalkosten: {min: 8.00, max:150.00, step: 0.1},
  platzbedarf:{min:10.0, max: 250, step:1},
}
export const changableKeys:ChangableKeys[] = Object.keys(ranges) as any

export const verfahrenDataRanges = {
  auslastung:         {min:30, max:100, step: 5},
  investitionssumme:  {min: 10000, max: 1500000, step: 5000},
  verschleissteile:   {min:0.0, max: 20.00, step: .01},
};

export const germanFormat = (value:number, precision:number|undefined =undefined, fixed=false) => {
  if(!precision) precision = value < 1 ? 4 : 2;
  const formatoptions = {
    maximumFractionDigits: precision,
    minimumFractionDigits: fixed ? precision : undefined,
  };
	return value.toLocaleString("de", formatoptions);
}