import React, { SetStateAction, Dispatch } from 'react';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { germanFormat } from '../computation/AdvancedConstSettings';
import type * as T from "../computation/Types";
import './InputSection.css';

const DICKE_TICKS = [1, 2, 3, 4, 5, 6, 8, 10, 12, 15, 20, 25, 50, 75, 100, 125, 150, 175, 200, 225, 260, 300];

function PickerRow({ name, selValue, values, setVal }:
	{ name: string, selValue: string, values: string[], setVal: (v: string) => void }) {
	return (
		<Form.Group>
			<Form.Label className="bold-header">{name}</Form.Label>
			<Form.Control as="select" value={selValue.toString()}
				onChange={(e: any) => setVal(e.target.value)} >
				{values.map(v => <option key={v}>{v}</option>)}
			</Form.Control>
		</Form.Group>
	);
}

function SliderRow({
	name, value, unit, setVal, min = 1, max = 100, step = 1,
	fixed2W = false, ticks = null, defaultVal = null,
}: {
	name: string, value: number, unit: string, setVal: (v: number) => void
	min?: number, max?: number, step?: number, fixed2W?: boolean,
	ticks?: number[] | null, defaultVal?: number|null,
}) {
	const revTicks = ticks ? Object.assign({}, ...ticks.map((t, i) => ({ [t]: i }))) : {};
	const tickToVal = (t: number) => ticks ? ticks[t] : t;
	const valToTick = (v: number) => ticks ? revTicks[v] : v;
	if (ticks) {
		min = 0; max = ticks.length - 1; step = 1;
	}
	return (
		<Form.Group>
			<Form.Label>
				<span className="bold-header">{name}</span>:&nbsp;
				<span style={{display: "inline-block"}}>
					{germanFormat(value)}&nbsp;
					<span className="unit">{unit}</span>
				</span>
			</Form.Label>
			<div className="d-flex flex-row">
				<Form.Control type="range" custom value={valToTick(value)}
					onChange={(e: any) => setVal(tickToVal(parseFloat(e.target.value)))}
					min={min} max={max} step={step} />
				{defaultVal !== null && (
					<div onClick={() => setVal(defaultVal)} className="clickable">
						<img alt="reset" src="/reset.png" height="20" className="ml-2 align-top"
							style={value === defaultVal ? {opacity: 0.3} : {}}/>
					</div>
				)}
			</div>
		</Form.Group>
	);
}

function SwitchRow({ name, onName, offName, value, subName, enabled, id, setVal }:
	{
		name: string, onName?: string, offName?: string, value?: string, subName?: string,
		enabled: boolean, id: string, setVal: (v: boolean) => void
	}) {
	const yes = onName || "berücksichtigen";
	const no = offName || "ignorieren";
	return (<div>
		<Form.Row className="justify-content-between flex-nowrap ">
			<div>
				<Form.Label className="mb-0 bold-header">{name}</Form.Label>
				<Form.Label className="ml-1 mr-1 value"><b>{value}</b></Form.Label>
			</div>
			<div className="d-flex">
				<Form.Label className="mr-2 switchStatus">{enabled ? yes : no}</Form.Label>
				<Form.Check type="switch" label="" id={id} checked={enabled}
					onChange={(e: any) => setVal(e.target.checked)} />
			</div>
		</Form.Row>
		<Form.Row><Form.Label className="text-secondary">{subName} </Form.Label></Form.Row>
	</div>
	);
}

function AspectRow({ inputVals, setInputVals }: {
	inputVals: T.InputValues | null,
	setInputVals: Dispatch<SetStateAction<T.InputValues | null>>
}) {
	const isEnabled = inputVals?.aspect ?? true;
	const aspectRat = inputVals ? inputVals.aspectRatio : 1;
	return (
		<div className={(isEnabled ? "" : " disabled")}>
			<SwitchRow name="Aspektverhältnis" subName="Das Aspektverhältnis ist ein Maß für die Feinheit der Geometrie, die mit einem Schneidverfahren hergestellt werden kann. Es ist der Quotient aus Werkstoffdicke und Breite der gerade noch schneidbaren Details."
				value={germanFormat(aspectRat, 2, true)} id="swAspect" enabled={isEnabled}
				setVal={a => setInputVals(inputVals ? { ...inputVals, aspect: a } : null)} />
			{isEnabled && <Form.Control type="range" custom disabled={!isEnabled} min="0.1" max="25" step="0.1"
				value={inputVals?.aspectRatio ?? 6}
				onChange={(e: any) => setInputVals(inputVals ? { ...inputVals, aspectRatio: parseFloat(e.target.value) } : null)} />}
			{/*<div className="d-flex flex-row flex-nowrap aspectWrapper">
				<div className="labelSlider d-flex flex-column pr-4">
					<Form.Label>Breite: {inputVals?.aspectW} <span className="unit">mm</span></Form.Label>
					<Form.Label>Dicke : {inputVals?.dicke} <span className="unit">mm</span></Form.Label>
				</div>
				<div className="w-100">
					<Form.Control type="range"	 custom disabled={!isEnabled} min="0.1" max="15" step="0.1"
							value={inputVals?.aspectW ?? 6}
							onChange={(e:any) => setInputVals(inputVals ? {...inputVals, aspectW: e.target.value}: null)}/>
					<Form.Control type="range" custom disabled={true} value={inputVals?.dicke ?? 6} min="1" max={DICKE_TICKS[DICKE_TICKS.length-1]}/>
				</div>
	</div>*/}
		</div>
	);
}

function InputSection({ inputVals, setInputVals, hifasData }: {
	inputVals: T.InputValues | null,
	setInputVals: Dispatch<SetStateAction<T.InputValues | null>>,
	hifasData: T.HifasData | null
}) {
	return (
		<Card className="input">
			<Card.Header>Eingabeparameter</Card.Header>
			<Card.Body className="cardBody">
				<PickerRow name="Werkstoff" selValue={inputVals?.werkstoff ?? ""}
					setVal={w => setInputVals(inputVals ? { ...inputVals, werkstoff: w } : null)}
					values={hifasData?.materials ?? []} />
				<SliderRow name="Dicke" unit="mm"
					value={inputVals?.dicke ?? 6} ticks={DICKE_TICKS}
					setVal={d => setInputVals(inputVals ? { ...inputVals, dicke: d } : null)} />
				<SwitchRow name="Wärmeeinfluss" id="swHeat" onName="zulässig" offName="nicht zulässig"
					enabled={inputVals?.waermeeinfluss ?? true}
					setVal={w => setInputVals(inputVals ? { ...inputVals, waermeeinfluss: w } : null)} />
				<AspectRow inputVals={inputVals} setInputVals={setInputVals} />
			</Card.Body>
		</Card>
	);
}

export default InputSection;
export { SliderRow };