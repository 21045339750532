import React from 'react';
import Container from 'react-bootstrap/Container';
import { fetchContent } from '../computation/Datamanger';

export const ContentPage = ({ file }: { file: string }) => {
  const [data, setData] = React.useState("Laden...");
  React.useEffect(() => {
    (async () => {
      const data = await fetchContent(file);
      if (data) setData(data);
    })();
  }, [file]);
  return (
    <Container className="wrapper d-flex justify-content-center mt-4">
      <div dangerouslySetInnerHTML={{ __html: data }} style={{width: "80%", margin: "0 10%"}}></div>
    </Container>
  );
}