import {Dispatch, SetStateAction} from "react";
import type * as T from "../computation/Types";

export async function fetchHifasData(
    setData : Dispatch<SetStateAction<T.HifasData | null>>){
  try {
    const r = await fetch("data/data.json");
    const data = await r.json();
    //console.log(data);
    setData(data);
  }catch(e){
    console.error(e);
  }
}

export async function fetchMaterialData(
  material: T.MaterialKey,
  setData : (d: T.MaterialData) => void
){
  try {
    const r = await fetch(`data/material/${material}.json`);
    const data = await r.json();
    //console.log(data);
    setData(data);
  }catch(e){
    console.error(e);
}
}

export async function fetchContent(file: string){
  try {
    const r = await fetch(`content/${file}`);
    const data = await r.text();
    return data;
  }catch(e){
    console.error(e);
  }
}