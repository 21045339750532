
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "./Footer.css";
import { fetchContent } from '../computation/Datamanger';
import { Link } from 'react-router-dom';

export default function Footer() {
	return (
		<div>
			<footer className="footer">
				<Container className="text-center">
					© 2021 - <a href="https://tibb-ev.de/">junge technologien in der beruflichen bildung e. V.</a><br />
			Umsetzung <a href="https://xorigin.tech">XOrigin UG</a> <br />
					<Link to="/impressum">Impressum</Link> &nbsp; <Link to="/datenschutz">Datenschutz</Link>
				</Container>
			</footer>
		</div>
	)
}

export function FooterNotes({showNotes=true}: {showNotes?: boolean}) {
	const [notes, setNotes] = React.useState("");
	React.useEffect(() => {
		(async () => {
			const data = await fetchContent("bitte_beachten.html");
			if (data) setNotes(data);
		})();
	}, []);
	return (
		<footer>
			<Container>
				<Row className="justify-content-center">
					<Col className="footerImg">
						<img
							alt=""
							src="/BMWi.png"
							height="200"
							className="d-inline-block align-top"
						/>
					</Col>
					{showNotes && (<Col className="footerText">
						<div dangerouslySetInnerHTML={{ __html: notes }}></div>
					</Col>)}
				</Row>
			</Container>
		</footer>
	);
}