import React, { Dispatch, SetStateAction} from 'react';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import {SliderRow} from './InputSection';
import type * as T from "../computation/Types";
import Dialog from 'react-bootstrap-dialog'
import * as AdvancedConsts from "../computation/AdvancedConstSettings";
import {germanFormat} from "../computation/AdvancedConstSettings";
import "./DetailsSection.css";
const bcrypt = require('bcryptjs');


function InfoItem({name, value, secVal}:
		{name:string, value:number|string, secVal:string}){
	return (
		<ListGroupItem className="d-flex justify-content-between">
			<div className="name">
				{name}:
			</div>
			<div className="value">
				{typeof value === "number" ? germanFormat(value) : value}&nbsp;
				<span>{secVal?.length>2?secVal:""}</span>
			</div>
		</ListGroupItem>
	)
}

function AdvancedSettings({
	hifasData, advancedVals, setAdvancedVals, speedRow,
	verfahren, customMatCost, customVerfahrenData,
	setCustomMatCost, setCustomVerfahrenData,
	authorized, canChange,
}:{
	hifasData: T.HifasData,
	advancedVals: T.AdvancedValues,
	setAdvancedVals: Dispatch<SetStateAction<T.AdvancedValues|null>>,
	speedRow: T.SpeedRow,
	verfahren: T.Verfahren,
	customMatCost: T.CustomMaterialCost,
	setCustomMatCost: Dispatch<SetStateAction<T.CustomMaterialCost>>
	customVerfahrenData: T.CustomVerfahrenData,
	setCustomVerfahrenData: (d: T.CustomVerfahrenData) => void,
	authorized: boolean,
	canChange: () => boolean,
	//selectedKey: string | null,
}){
	const orgConsts = hifasData.constants;
	const advKeys = AdvancedConsts.changableKeys;
	const ranges = AdvancedConsts.ranges;
	const cutConsumeMat = Object.keys(speedRow).filter(k =>
		!["schnittgeschw", "aspekt", "anstechzeit"].includes(k));
	const idleMat = Object.keys(verfahren.idleConsumption);
	const consumptionMats = Array.from(new Set([...cutConsumeMat, ...idleMat]).values());
	return <Accordion defaultActiveKey="0">
		<Accordion.Toggle as={Card.Header} eventKey="0" className="advancedTitle">
			Verfahrensparameter
		</Accordion.Toggle>
		<Accordion.Collapse eventKey="0">
			<Card.Body>
				{(Object.keys(customVerfahrenData) as any).map((k:"auslastung"|"investitionssumme"|"verschleissteile") =>{
					const {desc, unit, value} = verfahren.general[k]; // TODO reset to default
					const {min, max, step} = AdvancedConsts.verfahrenDataRanges[k];
					return (
						<SliderRow key={k} name={desc} unit={unit}
							value={customVerfahrenData[k]}
							setVal={(v) => canChange() && setCustomVerfahrenData({...customVerfahrenData, [k]:v})}
							min={min} max={max} step={step} defaultVal={value}
						/>)})}
			</Card.Body>
		</Accordion.Collapse>
		<Accordion.Toggle as={Card.Header} eventKey="1" className="advancedTitle">
			Allgemeine Parameter
		</Accordion.Toggle>
		<Accordion.Collapse eventKey="1">
			<Card.Body>
				{advKeys.map(k =>
					<SliderRow name={orgConsts[k].desc} unit={orgConsts[k].unit} value={advancedVals[k]}
							setVal={(v) => canChange() && setAdvancedVals({...advancedVals, [k]:v})} key={k}
							min={ranges[k].min} max={ranges[k].max} step={ranges[k].step} defaultVal={orgConsts[k].value}/>)}
			</Card.Body>
		</Accordion.Collapse>
		<Accordion.Toggle as={Card.Header} eventKey="2" className="advancedTitle">
			Energiepreise
		</Accordion.Toggle>
		<Accordion.Collapse eventKey="2">
			<Card.Body>
				{consumptionMats.map(m =>{
					let {desc, unit, value} = hifasData.materialcost[m];
					// euro/l is sometimes ~ 0.00, so use €/m^3:
					let scale = 1;
					if(unit === "€/l"){
						unit = "€/m³";
						desc += " (Normaldruck)";
						scale = 1000;
					}
					// interval is [1/4*val; 5*val], with roughly 100 steps in the slider
					const step = parseFloat(((value*5-value*0.25)*scale / 100).toPrecision(1));
					const min = parseFloat((value*0.25*scale).toPrecision(4));
					return (
						<SliderRow key={m} name={desc} unit={unit}
							value={customMatCost[m]*scale} fixed2W={true}
							setVal={(v) => canChange() && setCustomMatCost({...customMatCost, [m]:v/scale})}
							min={min} max={value*5*scale} step={step} defaultVal={value*scale}
						/>)})}
			</Card.Body>
		</Accordion.Collapse>
	</Accordion>
}

function checkPassword(dialog:Dialog|null, pwdHash:any, setAuthorized: (a:boolean) => void, first=true){
	const checkAndEnable = () => {
		if(bcrypt.compareSync(dialog?.value, pwdHash)){
			console.log("correct password");
			setAuthorized(true);
		}else{
			checkPassword(dialog, pwdHash, setAuthorized, false);
		}
	}
	console.log("checking password");
	dialog?.show({
		title: "Passwort erforderlich",
		body: (first ? 
				"Um die erweiterten Einstellung zu ändern, benötigen Sie ein Passwort. Falls Ihnen das Passwort noch nicht vorliegt, nehmen Sie gerne Kontakt mit uns auf."
			: "Passwort inkorrekt. Bitte erneut versuchen:"),
		prompt: Dialog.PasswordPrompt({initialValue:"", placeholder: "Passwort"}),
		actions: [
			Dialog.Action("Kontakt aufnehmen", () => window.open("/kontakt", "_blank"), 'btn-info'),
			Dialog.Action("Schließen", () => dialog.hide(), 'btn-info'),
			Dialog.Action("Passwort prüfen", checkAndEnable, 'btn-primary'),
		],
		bsSize: 700,
	});
}

export default function DetailsSection({
	hifasData, advancedVals, setAdvancedVals, rowsData, selectedKey, customMatCost, customVerfahrenData, setCustomMatCost, setCustomVerfahrenData,
}:{
	hifasData: T.HifasData | null,
	advancedVals: T.AdvancedValues | null,
	setAdvancedVals: Dispatch<SetStateAction<T.AdvancedValues|null>>,
	rowsData: T.RowData,
	customMatCost: T.CustomMaterialCost,
	setCustomMatCost: Dispatch<SetStateAction<T.CustomMaterialCost>>
	customVerfahrenData: T.CustomVerfahrenData | null,
	setCustomVerfahrenData: (d: T.CustomVerfahrenData) => void,
	selectedKey: string | null,
}){
	const dialogRef = React.useRef(null as Dialog | null);
	const [authorized, setAuthorized] = React.useState(false);

	const canChange = () => {
		if(authorized) return true;
		checkPassword(dialogRef.current, hifasData?.constants.password.value, setAuthorized);
		return false;
	}

	let cardBody;
	if(selectedKey && hifasData && advancedVals && customVerfahrenData){
		const {usageInfo} = hifasData.verfahren[selectedKey];
		const {schnittgeschw, anstechzeit} = rowsData[selectedKey].data;
		cardBody = (
			<>
				<Dialog ref={(el) => { dialogRef.current = el}} />
				<ListGroup className="list-group-flush">
					<InfoItem name="Schnittgeschwindigkeit" value={schnittgeschw} secVal="mm/min"/>
					{anstechzeit && <InfoItem name="Anstechzeit" value={anstechzeit} secVal="sek"/>}
					{Object.keys(usageInfo).map(k =>
						<InfoItem name={usageInfo[k].desc} value={usageInfo[k].value}
								secVal={usageInfo[k].unit} key={k}/>)}
				</ListGroup>
				<Card.Body><Card.Title className="cardSubTitle">
					Kosten
				</Card.Title></Card.Body>
				<ListGroup className="list-group-flush costs">
					<ListGroupItem>
						<div>
							<span>{germanFormat(rowsData[selectedKey].priceHour, 2, true)}€</span> pro Stunde <br/>
							<span>{germanFormat(rowsData[selectedKey].price, 2, true)}€</span> pro Meter Schnittlänge <br/>
						</div>
					</ListGroupItem>
				</ListGroup>
				<AdvancedSettings
					hifasData={hifasData}
					advancedVals={advancedVals}
					setAdvancedVals={setAdvancedVals}
					speedRow={rowsData[selectedKey].data}
					verfahren={hifasData.verfahren[selectedKey]}
					customMatCost={customMatCost}
					setCustomMatCost={setCustomMatCost}
					customVerfahrenData={customVerfahrenData}
					setCustomVerfahrenData={setCustomVerfahrenData}
					authorized={authorized}
					canChange={canChange}
				/>
			</>
		);
	}else{
		cardBody = 	(
			<Card.Body>
				<Card.Text>Bitte ein Schneidverfahren auswählen, um weitere Information zu erhalten.</Card.Text>
			</Card.Body>
		);
	}
	return (
		<Card className="cardWrapper">
			<Card.Header>Ergebnis</Card.Header>
			{cardBody}
	</Card>
	);
}