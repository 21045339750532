import type * as T from "../computation/Types";
import "./FixedResults.css";
import { Item } from "./ListSection";


interface FixedResultProps {
  name: T.VerfahrenName,
verfahrenData: T.Verfahren,
onClick: () => void,
isSel: boolean,
state: {pos:boolean, price: number},
}
const FixedResult = (props:FixedResultProps) => {
  return (
    <div className="fixedResult">
      <h3>Auswahl:</h3>
      <Item {...props} isRow={false}/>
    </div>
  );
};

export default FixedResult;
