import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import './MyNavbar.css';

function MyNavbar() {
	return (
    <div>
      <Navbar bg="white" variant="light" className="justify-content-between">
        <Navbar.Brand>
          <Link to="/">
            hifas.de
          </Link>
        </Navbar.Brand>
        <Navbar.Text>
          <img
              alt=""
              src="/tibb-logo.png"
              //width="30"
              height="40"
              className="d-inline-block align-top"
            />{' '}
        </Navbar.Text>
      </Navbar>
      <Navbar className="justify-content-between nav2 flex-wrap">
      
        <Navbar.Text>
          Hilfe zur Auswahl von Schneidverfahren
        </Navbar.Text>
      
        <Navbar.Text>
          Ein Service von: junge technologien in der beruflichen bildung e. V.
        </Navbar.Text>

      </Navbar>
    </div>
	);
}

export default MyNavbar;