import React, { Dispatch, SetStateAction } from 'react';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {FaCheckCircle, FaTimesCircle} from "react-icons/fa"
import type * as T from "../computation/Types";
import './ListSection.css';

function PriceLabel({price}:{price:number}){
	const priceEuro = Math.floor(price);
	const priceCent = Math.floor(price*100) - priceEuro * 100;
	const priceCentStr = (priceCent <= 9 ? "0": "") + priceCent;
	return (	
		<div className="price d-flex align-items-end">
			<div className="euro">
				{priceEuro}
			</div>
			<div className="comma">,</div>
			<div className="centUnit">
				<div>{priceCentStr}</div>
				<div className="unit">€/m</div>
			</div>
		</div>
	);
}

export function Item({name, verfahrenData, onClick, isSel, state, isRow=true}:{
	name: T.VerfahrenName,
	verfahrenData: T.Verfahren,
	onClick: () => void,
	isSel: boolean,
	state: {pos:boolean, price: number},
	isRow?: boolean,
}){	
	return (
		<ListGroup.Item action={state.pos && isRow} active={isSel} onClick={state.pos?onClick:()=>{}}>
			<Container className={"listItem" + (state.pos?"":" disabled")}>
				<Row className="flex-nowrap align-items-center">
					{isRow && (state.pos ?
						<FaCheckCircle size="40" color="#070"/>
					: <FaTimesCircle size="40" color="#A00"/>
					)}
					<Col className="d-flex flex-column justify-content-center">
						<div className="listName">{verfahrenData.general.title.value}</div>
						<div className="listSubName">{verfahrenData.general.subtitle.value}</div>
					</Col>
					<div className="d-flex justify-content-end">
						<PriceLabel price={state.price} />
					</div>
				</Row>
			</Container>
		</ListGroup.Item>
	)
}

function ListSection({data, verfahren, selectedKey, setSelected}:{
		data: T.RowData,
		verfahren: Record<T.VerfahrenName, T.Verfahren>
		selectedKey: T.VerfahrenName|null,
		setSelected: Dispatch<SetStateAction<T.VerfahrenName|null>>}){
	return (
		<Card className="cardWrapper">
			<Card.Header>Schneidverfahren</Card.Header>
			<Card.Body className="pb-0">
			</Card.Body>
			<ListGroup variant="flush">
				{Object.keys(data).map(tool => 
					<Item key={tool} name={tool} isSel={tool===selectedKey} state={data[tool]}
							verfahrenData={verfahren[tool]} onClick={() => setSelected(tool)}/>)}
			</ListGroup>
		</Card>
	);
}

export default ListSection;