import React from 'react';
import './App.css';
import MyNavbar from './components/MyNavbar'
import InputSection from './components/InputSection';
import ListSection from './components/ListSection';
import DetailsSection from './components/DetailsSection';
import FixedResult from './components/FixedResult';
import Footer, { FooterNotes } from './components/Footer'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { calcRowData } from './computation/Kostenberechnung';
import { fetchHifasData, fetchMaterialData } from './computation/Datamanger';
import type * as T from "./computation/Types";

import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
import { ContentPage } from './components/ContentPage';
import { Button } from 'react-bootstrap';


function MainPage() {
  const [hifasData, setHifasData] = React.useState(null as (T.HifasData | null));
  const [materialData, setMaterialData] = React.useState({} as Record<T.MaterialKey, T.MaterialData>);
  const [customMatCost, setCustomMatCost] = React.useState({} as T.CustomMaterialCost);
  const [customVerfahren, setCustomVerfahren] = React.useState({} as T.CustomVerfahrenDataMap);
  const [inputVals, setInputVals] = React.useState(null as (T.InputValues | null));
  const [advancedVals, setAdvancedVals] = React.useState(null as (T.AdvancedValues | null));
  const [rowsData, setRowData] = React.useState({} as T.RowData);
  const [selKey, setSelKey] = React.useState(null as (T.VerfahrenName | null));

  React.useEffect(() => {
    fetchHifasData(setHifasData);
  }, []);

  React.useEffect(() => {
    if (inputVals && !materialData[inputVals.werkstoff]) {
      fetchMaterialData(inputVals.werkstoff, (d) =>
        setMaterialData({ ...materialData, [inputVals.werkstoff]: d }));
    }
  }, [inputVals, materialData]);

  React.useEffect(() => {
    if (hifasData) {  // data loaded
      setInputVals({
        werkstoff: hifasData.materials[0],
        dicke: 6,
        waermeeinfluss: true,
        aspect: false,
        aspectRatio: 1,
      });
      // set advanced values defaults
      const advKeys: any = Object.keys(hifasData.constants);
      const advVals = Object.assign({}, ...advKeys.map((k: T.AdvancedKeys) =>
        ({ [k]: hifasData.constants[k].value })));
      setAdvancedVals(advVals);

      setCustomMatCost(Object.assign({}, ...Object.keys(hifasData.materialcost).map(c =>
        ({ [c]: hifasData.materialcost[c].value }))));
      setCustomVerfahren(Object.assign({}, ...Object.keys(hifasData.verfahren).map(vf => {
        const { investitionssumme: i, auslastung: a, verschleissteile: v } =
          hifasData.verfahren[vf].general;
        return {
          [vf]: {
            investitionssumme: i.value,
            auslastung: a.value,
            verschleissteile: v.value
          }
        }
      })));
    }
  }, [hifasData]);

  React.useEffect(() => {
    if (inputVals && advancedVals && hifasData && materialData[inputVals.werkstoff]
      && Object.keys(customMatCost).length > 0 && Object.keys(customVerfahren).length > 0) {
      const newRowData = calcRowData(inputVals, advancedVals,
        hifasData, materialData[inputVals.werkstoff], customMatCost, customVerfahren)
      if (selKey && !newRowData[selKey].pos) setSelKey(null);
      setRowData(newRowData);
      //setSelKey(Object.keys(hifasData.verfahren)[1]);//TODO @TEST
    }
  }, [inputVals, advancedVals, hifasData, materialData, customMatCost, customVerfahren, selKey]);

  return (
    <>
      <Container className="wrapper d-flex justify-content-center flex-column">
        <Row className="justify-content-center mt-4">
          {/*<Link to="/willkommen" style={{ color: "#aaa", textDecoration: "underline" }}>
            Bedienungshinweise
          </Link>*/}
          <Link to="/willkommen">
            <Button size="lg" variant="primary">Was ist hifas?</Button>
          </Link>
        </Row>
        <Row>
          <Col>
            <InputSection inputVals={inputVals} setInputVals={setInputVals} hifasData={hifasData} />
          </Col>
          <Col>
            <ListSection data={rowsData} setSelected={setSelKey}
              selectedKey={selKey} verfahren={hifasData?.verfahren ?? {}} />
          </Col>
          <Col>
            <DetailsSection hifasData={hifasData} advancedVals={advancedVals}
              setAdvancedVals={setAdvancedVals}
              customMatCost={customMatCost} setCustomMatCost={setCustomMatCost}
              customVerfahrenData={selKey ? customVerfahren[selKey] : null}
              setCustomVerfahrenData={(d: T.CustomVerfahrenData) =>
                selKey && setCustomVerfahren({ ...customVerfahren, [selKey]: d })}
              rowsData={rowsData} selectedKey={selKey} />
          </Col>
        </Row>
      </Container>
      {selKey && hifasData?.verfahren?.[selKey] && (
        <FixedResult
          name={selKey}
          verfahrenData={hifasData?.verfahren?.[selKey]}
          state={rowsData[selKey]}
          isSel={false}
          onClick={() => undefined}
        />
      )}
    </>
  );
}

function GoToMain() {
  return (
    <Container className="d-flex justify-content-center mb-5 mt-5">
      <Link to="/">
        <Button size="lg" variant="primary">Weiter zur Anwendung</Button>
      </Link>
    </Container>
  );
}

function App() {
  const [redir, setRedir] = React.useState(false);
  React.useEffect(() => {
    try {
      if (!localStorage.getItem("returning")) {
        localStorage.setItem("returning", "yes");
        setRedir(true);
      }else setRedir(false);
    } catch(ex){
      console.log("Could not load local storage.");
    }
  }, [redir]);
  
  return (
    <Router>
      <div className="superWrapper">
        <MyNavbar />
        <Switch>
          <Route path="/impressum">
            <ContentPage file="impressum.html" />
          </Route>
          <Route path="/datenschutz">
            <ContentPage file="datenschutz.html" />
          </Route>
          <Route path="/willkommen">
            <ContentPage file="willkommen.html" />
            <GoToMain />
            <FooterNotes showNotes={false} />
          </Route>
          <Route path="/kontakt">
            <ContentPage file="Kontakt_aufnehmen.html" />
          </Route>
          <Route path="/">
            {redir ? <Redirect to="/willkommen" /> : <MainPage />}
            <FooterNotes />
          </Route>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
